import React from "react";
import { storyblokEditable } from "@storyblok/react";

import SectionHeading from "@components/molecules/SectionHeading";
import RichText from "@components/atoms/RichText";

import { SectionHeadingStoryblokProps } from "./types";

const SectionHeadingStoryblok: React.FC<
  SectionHeadingStoryblokProps
> = ({ blok }) => {
  const { titleBlock, descriptionBlock, subtitle } = blok;

  return titleBlock[0]?.text ? (
    <SectionHeading
      title={<RichText content={titleBlock?.[0].text} />}
      description={
        descriptionBlock?.[0]?.text?.content[0].content?.length ? (
          <RichText content={descriptionBlock[0].text} />
        ) : undefined
      }
      {...{ subtitle, ...storyblokEditable(blok) }}
    />
  ) : null;
};

export default SectionHeadingStoryblok;
