import styled from "styled-components";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

export const Wrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Title = styled(Typography).attrs({
  variant: "heading4"
})``;

export const Subtitle = styled(Typography).attrs({
  variant: "body1"
})`
  text-transform: uppercase;
  margin-bottom: 2.3125rem;
  font-family: ${({ theme }) => theme.fonts.heading};
`;

export const Description = styled(Typography).attrs({
  variant: "body7"
})`
  margin-top: 0.625rem;

  @media ${media.mobile} {
    margin-top: 2.25rem;
  }
`;
