import { Box } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import * as Styled from "./styles";
import { SectionHeadingProps } from "./types";

const SectionHeading: React.FC<SectionHeadingProps> = ({
  title,
  subtitle,
  description
}) => (
  <Styled.Wrapper>
    {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
    {typeof title === "string" ? (
      <Styled.Title>{title}</Styled.Title>
    ) : (
      title
    )}
    {description && typeof description === "string" && (
      <Styled.Description>{description}</Styled.Description>
    )}
    {description && typeof description !== "string" && (
      <Box mt={["0.625rem", "", "2.25rem"]}>{description}</Box>
    )}
  </Styled.Wrapper>
);

export default SectionHeading;
